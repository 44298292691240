import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import '../../static/css/faq.css';
import { fetchData } from '../../api/productPageAPI';

const Faq = () => {
  let { product_name_slug } = useParams();
  let { guideSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const [faqData, setFaqData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    fetchData(`${product_name_slug}/user_manual`).then((result) => setFaqData(result.find(item => item.is_faq === true && item.user_manual_slug === guideSlug)));
    return () => clearTimeout(timer);
  }, [product_name_slug, guideSlug]);

  if (faqData) {
    return (
      <div className="detailed-faq-content">
        <h1>
          <Link to={`/${faqData.product_slug}`}>
            {faqData.product_name}
          </Link>
        </h1>
        <div dangerouslySetInnerHTML={{ __html: faqData.user_manual_content }}></div>
      </div>
    )
  } else {
    return loading ? <h3>Loading...</h3> : <h3>Not found</h3>;
  }
}

export default Faq