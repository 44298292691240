import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VideoPlayer = ({ video, content, onClose }) => {
    const [modal, setModal] = useState(false);
    const [pause, setPause] = useState(false);
    const videoRef = useRef();

    useEffect(() => {
        if (videoRef?.current) {
            if (!modal) {
                videoRef.current.play();
            } else if (modal) {
                videoRef.current.pause();
            }
        }
    }, [modal]);

    const openVideo = useCallback(() => {
        setModal(prevModal => !prevModal);
        if (onClose) {
            onClose();
        }
    }, [onClose]);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                openVideo();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [openVideo]);
    if (!modal) {
        return (
            <div className="VideoPopupDiv" id="VideoPopupDiv" style={{ display: 'block' }}>
                <div className="InnerVideoDiv">
                    <video
                        poster={content}
                        autoPlay
                        ref={videoRef}
                        controls
                        id="ProductVideo"
                        width="100%"
                        onPlay={() => { setPause(false) }}
                        onPause={() => { setPause(true) }}
                    >
                        <track kind="captions" />
                        <source id="ProductVideoSource" src={video}></source>
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <button className="CloseVideoPopupButton" onClick={openVideo}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </button>
                    {pause ?
                        <div className="PlayIcon">
                            <svg onClick={() => {
                                setPause(false);
                                if (videoRef.current && videoRef.current.paused) {
                                    videoRef.current.play();
                                }
                            }}
                                style={{ cursor: 'pointer' }}
                                xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" className=''>
                                <circle cx="29" cy="29" r="29" fill="white" fillOpacity="0.6" />
                                <path d="M40.3984 25.536C43.0651 27.0756 43.0651 30.9246 40.3984 32.4642L26.2984 40.6048C23.6318 42.1444 20.2984 40.2199 20.2984 37.1407L20.2984 20.8595C20.2984 17.7803 23.6318 15.8558 26.2984 17.3954L40.3984 25.536Z" fill="black" fillOpacity="0.6" />
                            </svg>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

VideoPlayer.propTypes = {
    video: PropTypes.string,
    content: PropTypes.string
};

export default VideoPlayer