import React, { useEffect } from 'react'
import '.././static/css/mediaCoverage.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MediaCoverage = () => {
    const { t } = useTranslation()
    document.title = t('media_coverage.title')

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='media-header'>
                <h1>{t('media_coverage.title')}</h1>
                <p>{t('media_coverage.description')}</p>
            </div>
            <div className="media">
                <div className='media-block'>
                    <div className="inner-media">
                        <div className="product-coverage-header">
                            <Link to={'/product/ftp-client/'} className="product-coverage">
                                <h2>Auto FTP Manager</h2>
                            </Link>
                            <div className="media-coverage">
                                <Link to={'https://www.winsite.com/Internet/FTP/Auto-FTP-Manager/'}>
                                    <h3>Automate Your FTP Transfers Easily Through Auto <br />FTP Manager</h3>
                                    <p>Winsite</p>
                                </Link>
                            </div>
                            <div className="media-coverage">
                                <Link to={'https://www.smartfile.com/blog/review-auto-ftp-manager-5-15/'}>
                                    <h3>Review of Auto FTP Manager 5.15</h3>
                                    <p>SmartFile blog</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="inner-media">
                        <div className="product-coverage-header">
                            <Link to={'/product/video-surveillance-software/'} className="product-coverage">
                                <h2>Security Monitor Pro</h2>
                            </Link>
                            <div className="media-coverage">
                                <Link to={'https://www.tomsguide.com/us/PC-Based-Home-Security,review-1630-8.html'}>
                                    <h3>PC-Based Home Security: Do It Yourself</h3>
                                    <p>Tom's Guide website</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='media-block'>
                    <div className="inner-media">
                        <div className="product-coverage-header">
                            <Link to={'/product/video-screen-capture/'} className="product-coverage">
                                <h2>My Screen Recorder Pro</h2>
                            </Link>
                            <div className="media-coverage">
                                <Link to={'https://learn.microsoft.com/en-us/previous-versions/technet-magazine/cc137759(v=msdn.10)?redirectedfrom=MSDN'}>
                                    <h3>ToolboxNew Products for IT Pros</h3>
                                    <p>Microsoft Technet magazine</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="inner-media">
                        <div className="product-coverage-header">
                            <Link to={'/product/screen-recorder/'} className="product-coverage">
                                <h2>My Screen Recorder</h2>
                            </Link>
                            <div className="media-coverage">
                                <Link to={'https://www.brighthub.com/multimedia/video/articles/14502/'}>
                                    <h3>My Screen Recorder Guide: How to Re-<br />cord Anything on Your Computer <br />Screen</h3>
                                    <p>Brighthub</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MediaCoverage