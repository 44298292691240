import * as React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import "./App.css";
import { useTranslation } from "react-i18next";


function App() {
  const { t } = useTranslation();

  setTimeout(() => {
    document.title = `Deskshare - ${t("title")}`;
  }, 1000);

  React.useEffect(() => {
    const handleMessage = (e) => {
      localStorage.setItem("payload", JSON.stringify(e.data));
      localStorage.setItem("event_data", JSON.stringify(e));
      
      if (e.origin !== 'https://test.shop.deskshare.com') {
        return;
      }

      localStorage.setItem("event_data_current_origin", JSON.stringify(e));

      try {
        const data = JSON.parse(e.data);
        if (typeof data.name !== "undefined") {
          localStorage.setItem("isuser_loggedin", data.name);
          localStorage.setItem("user_object_id", data.user_object_id);
        }
      } catch (error) {
        localStorage.setItem("error", String(error));
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="page">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
