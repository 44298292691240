export const CameraRequestAPI = async (data) => {

    const apiData = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phone || '',
        email: data.email,
        camera_brand: data.cameraBrand,
        camera_model: data.cameraModel,
        ipaddress: data.cameraIP,
        port_number: data.portNumber,
        camera_username: data.username || '',
        camera_password: data.password || '',
        camera_instruct: data.instruction || ''
    };

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/camera-request-form`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
    })
        .then(response => {
            return response.json().then(data => {
                if (!response.ok) {
                    let err = new Error(data.message);
                    err.status = response.status;
                    throw err;
                }
                return {
                    message: data.message,
                    status: response.status
                };
            });
        })
        .catch((error) => ({
            message: error.message,
            status: error.status
        }));
};