import axios from 'axios';

export const commonAPI = async (path) => {
    let lang = localStorage.getItem('lang') || 'en';

    const api = axios.create({
        baseURL: `${process.env.REACT_APP_BACKEND_URL}/${lang}/api/`,
    });

    try {
        const response = await api.get(path);
        return response?.data;
    } catch (error) {
        console.error(error);
    }
};