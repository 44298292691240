import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../static/css/supportPages/lostLicenseKey.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LostLicenseAPI } from '../../api/lostLicenseKeyAPI';
import { useTranslation } from 'react-i18next';
import ThankYou from '../../components/ThankYou';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const applications = ["Auto FTP Manager", "Call For Help", "Dictation Pro", "Document Translator", "FTP Manager Lite", "FTP Manager Pro", "IP Camera Monitor", "IP Camera Viewer", "IP Phone Camera", "Magnifier - Vision Enhancer", "Medical Reminder", "My Screen Recorder", "My Screen Recorder Pro", "Paper Scanner Pro", "Mobile to PC File Transfer", "Portable Scandroid", "Screen Cast", "Security Monitor Pro", "Senior Safety Phone", "Text Speaker Live", "WebCam Monitor"];

const LostLicenseKey = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('support_pages.lost_license.license_key_form.validation_messages.f_name')),
    lastName: Yup.string().required(t('support_pages.lost_license.license_key_form.validation_messages.l_name')),
    email: Yup.string().email(t('support_pages.lost_license.license_key_form.validation_messages.email1')).required(t('support_pages.lost_license.license_key_form.validation_messages.email2')),
    application: Yup.string().required(t('support_pages.lost_license.license_key_form.validation_messages.product')),
  });

  document.title = t('support_pages.lost_license.title')
  const lang = localStorage.getItem('lang');
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [application, setApplication] = useState("");
  const [apiResponse, setApiResponse] = useState()
  const [showResponse, setShowResponse] = useState(false);
  const [startDate, setStartDate] = useState(new Date());


  useEffect(() => {
    window.scrollTo(0, 0);
    if (apiResponse !== undefined) {
      setShowResponse(true);
      setTimeout(() => {
        setShowResponse(false);
        navigate('/');
      }, 10000);
    }
  }, [apiResponse, navigate]);

  const date = String(startDate.getDate()).padStart(2, '0');
  const month = String(startDate.getMonth() + 1).padStart(2, '0');
  const year = startDate.getFullYear();
  const formattedDate = `${date}/${month}/${year}`;


  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setApiResponse(undefined);
    let formData = { ...values, phone, formattedDate };
    const response = await LostLicenseAPI(formData);
    setApplication(values.application);
    setApiResponse(response);
    if (response.status === 200) {
      resetForm();
      setPhone('');
    }
    setSubmitting(false);
  }

  return (
    <>
      <Helmet>
        <html lang={lang}></html>
        <meta name="description" content="Retrieve your lost license key by using an automated system." />
        <meta name="keywords" content="lost license number, lost license key, lost license code, license key missing, license lost, lost product key" />
      </Helmet>
      {
        showResponse && apiResponse && apiResponse.status === 200 ?
          <ThankYou applicationName={application} />
          :
          <>
            <div className='key-header'>
              <h1>{t('support_pages.lost_license.title')}</h1>
              <p>{t('support_pages.lost_license.description1')}
                <Link to={'https://shop.deskshare.com/login/'}>
                  {t('support_pages.lost_license.sign_in')}
                </Link>
                {t('support_pages.lost_license.description2')}
              </p>
            </div>
            <div className="lost-license-form">
              {showResponse && apiResponse ? <span style={{ color: 'red', fontSize: '0.8vw' }} >{apiResponse.message}</span> : null}
              <Formik
                initialValues={{ firstName: '', lastName: '', email: '', application: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, formikBag) => { handleFormSubmit(values, formikBag) }}
              >
                {({ values }) => {
                  const selected = values.application !== "";
                  return (
                    <Form className='lost-license-form'>
                      <div className="key-form">
                        <div className="key-signin">
                          <p>{t('support_pages.lost_license.license_key_form.question')}</p>
                          <p>{t('support_pages.lost_license.license_key_form.description')}</p>
                          <p>{t('support_pages.lost_license.license_key_form.description2')}</p>
                          <div className="key-account-form">
                            <label htmlFor="firstName">{t('support_pages.lost_license.license_key_form.label.full_name')}</label>
                            <div></div>
                            <div>
                              <Field name="firstName" placeholder={t('support_pages.lost_license.license_key_form.placeholders.f_name')} />
                              <ErrorMessage className='error-message' name="firstName" component="div" />
                            </div>
                            <div>
                              <Field name="lastName" placeholder={t('support_pages.lost_license.license_key_form.placeholders.l_name')} />
                              <ErrorMessage className='error-message' name="lastName" component="div" />
                            </div>
                            <label htmlFor="email">Email</label>
                            <label htmlFor="phone">{t('support_pages.lost_license.license_key_form.label.phone')}</label>
                            <div>
                              <Field type="email" name="email" placeholder='you@domain.com' />
                              <ErrorMessage className='error-message' name="email" component="div" />
                            </div>
                            <PhoneInput country={'us'} value={phone} onChange={phone => setPhone(phone)} />
                            <label htmlFor="application">{t('support_pages.lost_license.license_key_form.label.application')}</label>
                            <label htmlFor="date">{t('support_pages.lost_license.license_key_form.label.date')}</label>
                            <div>
                              <Field as="select" name="application" id="application"
                                style={{ color: selected ? 'black' : '#8a7575' }}>
                                <option value="" hidden="">{t('support_pages.lost_license.license_key_form.placeholders.product')}</option>
                                {applications.map((app) => (
                                  <option key={app} value={app}>{app}</option>
                                ))}
                              </Field>
                              <ErrorMessage className='error-message' name="application" component="div" />
                            </div>
                            <div>
                              <DatePicker
                                selected={startDate}
                                showIcon
                                toggleCalendarOnIconClick
                                maxDate={new Date()}
                                onChange={(date) => setStartDate(date)} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="submit">{t('support_pages.lost_license.license_key_form.submit')}</button>
                    </Form>
                  )
                }}
              </Formik>
            </div >
          </>
      }
    </>
  )
}

export default LostLicenseKey