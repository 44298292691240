import Cookies from "js-cookie";
import axios from "axios";

// export const getShoppingCartLoginStatus = async () => {
//     axios.get("https://shop2.deskshare.com/wp-json/deskshare/v1/is_user_logged_in", {
//         // headers: {
//         //     'X-Client-Request': 'true',
//         //     "Authorization": `Basic ${process.env.REACT_APP_SHOPPING_CART_AUTH_TOKEN}`
//         // }
//         headers: {
//             'X-Client-Request': 'true'
//         },
//         withCredentials: true
//     })
//         .then(response => {
//             console.log(response.data);
//         })
//         .catch(error => {
//             console.error(error.response || error);
//         });
// };

// export const getShoppingCartLoginStatus = async () => {
//     try {
//         const response = await axios.get("https://shop2.deskshare.com/wp-json/deskshare/v1/is_user_logged_in", {
//             headers: {
//                 'X-Client-Request': 'true'
//             },
//             withCredentials: true
//         });
//         console.log(response.data);
//     } catch (error) {
//         console.error(error.response || error);
//     }
// };

export const getShoppingCartLoginStatus = async () => {
  if (localStorage.getItem("is_user") === "true") {
    localStorage.setItem("isuser_loggedin", "true");
    return { isuser_loggedin: true, is_user: true };
  }

  return { isuser_loggedin: false, is_user: false };
};

// export const getShoppingCartLoginStatus = async () => {
//   const isuser_loggedin = [
//     ...Object.keys(Cookies.get()).filter(
//       (key) =>
//         key.startsWith("wordpress") ||
//         key.startsWith("woocommerce") ||
//         key.startsWith("wp") ||
//         key.startsWith("tk")
//     ),
//   ].some((item) => item);

//   console.log("all cookies" , Cookies.get());
//   localStorage.setItem("all_cookies", JSON.stringify(Cookies.get()));

//   const is_user = [Cookies.get("wordpress_test_cookie")].some((item) => item);

//   console.log("isuser_loggedin", isuser_loggedin);
//   console.log("is_user", is_user);

//   localStorage.setItem("isuser_loggedin", isuser_loggedin);
//   localStorage.setItem("is_user", is_user);

//   return { isuser_loggedin, is_user };
// };

// export const getShoppingCartLoginStatus = async () => {
//   const url =
//     "https://test.shop.deskshare.com/wp-json/v1/is_user_logged_in?username=alice.kevin";

// const username = process.env.WORDPRESS_USERNAME;
// const password = process.env.WORDPRESS_PASSWORD;

//   const headers = {
//     "Authorization":
//       "Basic " + btoa(username + ":" + password),
//   };

//   axios
//     .get(url, { headers: headers })
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.error("There was a problem with the axios operation:", error);
//     });
// };
