import React from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/productFeatures.css';
import { useTranslation } from "react-i18next";

const ProductFeatures = ({ features, type }) => {
    const { t } = useTranslation();

    return (
        <div className="feature-list">
            {(type !== 'free') ?
                <span className="list-text">{t('product_page.features.title')}:</span>
                : <span className="list-text">Features:</span>}
            <ul>
                {
                    features?.map((purchaseFeature) => {
                        return (
                            <li className='feature-text' key={purchaseFeature.id}>{purchaseFeature?.feature}</li>
                        )
                    })
                }
            </ul>
        </div>
    );
};

ProductFeatures.propTypes = {
    features: PropTypes.array,
    type: PropTypes.string,
    inApp: PropTypes.bool
};

export default ProductFeatures;