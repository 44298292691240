import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../static/css/company/contactUs.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { contactUsAPI } from '../../api/contactUsAPI';
import { useTranslation } from 'react-i18next';
import ThankYou from '../../components/ThankYou';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const ContactUs = () => {
    const { t } = useTranslation();
    document.title = t('company.contact_us.title')

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('company.contact_us.form.validation_messages.f_name')),
        lastName: Yup.string().required(t('company.contact_us.form.validation_messages.l_name')),
        email: Yup.string().email(t('company.contact_us.form.validation_messages.email1')).required(t('company.contact_us.form.validation_messages.email2')),
        subject: Yup.string().required(t('company.contact_us.form.validation_messages.subject')),
        message: Yup.string().required(t('company.contact_us.form.validation_messages.message1')).max(1000, t('company.contact_us.form.validation_messages.message2'))
    });

    const navigate = useNavigate();
    const [phone, setPhone] = useState()
    const [text, setText] = useState('');
    const [apiResponse, setApiResponse] = useState();
    const [showResponse, setShowResponse] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (apiResponse !== undefined) {
            setShowResponse(true);
            setTimeout(() => {
                setShowResponse(false);
                navigate('/');
            }, 10000);
        }
    }, [apiResponse, navigate]);

    const handleCount = (event) => {
        setText(event.target.value);
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        setApiResponse(undefined);
        let formData = { ...values, phone };
        const response = await contactUsAPI(formData);
        setApiResponse(response);
        if (response.status === 200) {
            resetForm();
            setPhone('');
        }
        setSubmitting(false);
    }

    return (
        <>
            {
                showResponse && apiResponse && apiResponse.status === 200 ?
                    <ThankYou />
                    :
                    <>
                        <div className='contactus-header'>
                            <h1>{t('company.contact_us.title')}</h1>
                            <p>
                                {t('company.contact_us.description1')}
                                {" "}<Link to={AddLangToPath('/product-support/')} >{t('company.contact_us.description2')}</Link>{" "}
                                {t('company.contact_us.description3')}
                            </p>
                            <p>{t('company.contact_us.description4')}</p>
                        </div>
                        <div className="contactus-form">
                            <div className="contactus-submit">
                                {showResponse && apiResponse ? <p style={{ color: 'red', fontSize: '0.8vw', textAlign: 'center', margin: '0' }}>{apiResponse.message}</p> : null}
                                <Formik
                                    initialValues={{ firstName: '', lastName: '', email: '', subject: '', message: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, formikBag) => { handleFormSubmit(values, formikBag) }}
                                >
                                    {({ handleChange, handleBlur }) => (
                                        <Form>
                                            <div className="contactus-main-form">
                                                <label htmlFor="firstName">{t('company.contact_us.form.label.full_name')}</label>
                                                <div></div>
                                                <div>
                                                    <Field name="firstName" id='firstName' placeholder={t('company.contact_us.form.placeholder.f_name')} />
                                                    <ErrorMessage className='error-message' name="firstName" component="div" />
                                                </div>
                                                <div>
                                                    <Field name="lastName" placeholder={t('company.contact_us.form.placeholder.l_name')} />
                                                    <ErrorMessage className='error-message' name="lastName" component="div" />
                                                </div>
                                                <label htmlFor="email">{t('company.contact_us.form.label.email')}</label>
                                                <label htmlFor="phone">{t('company.contact_us.form.label.phone')}</label>
                                                <div>
                                                    <Field type="email" name="email" id='email' placeholder='you@domain.com' />
                                                    <ErrorMessage className='error-message' name="email" component="div" />
                                                </div>
                                                <PhoneInput country={'us'} value={phone} onChange={phone => setPhone(phone)} name='phone' id="phone" />
                                            </div>
                                            <label htmlFor="subject">{t('company.contact_us.form.label.subject')}</label>
                                            <div className='subject'>
                                                <Field name="subject" id='subject' placeholder={t('company.contact_us.form.placeholder.subject')} />
                                                <ErrorMessage className='error-message' name="subject" component="div" />
                                            </div>
                                            <div className='contact-message-box'>
                                                <div className="contact-message-box-content">
                                                    <label htmlFor="message">{t('company.contact_us.form.label.message')}</label>
                                                    <p>{text.length}/1000</p>
                                                </div>
                                                <div className="customer-message">
                                                    <Field
                                                        name="message"
                                                        as='textarea'
                                                        placeholder={t('company.contact_us.form.placeholder.message')}
                                                        id="message"
                                                        cols="30"
                                                        rows="10"
                                                        maxLength="1000"
                                                        onChange={event => {
                                                            handleCount(event);
                                                            handleChange(event);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    <ErrorMessage className='error-message' name="message" component="div" />
                                                </div>
                                            </div>
                                            <button type="submit">{t('company.contact_us.form.submit')}</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default ContactUs