import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdLanguage } from "react-icons/md";
import {
  faAngleDown,
  faCaretDown,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../../static/css/common/header.css";
import IPDetails from "../../helper/IPDetails";
import { Helmet } from "react-helmet";
import { commonAPI } from "../../api/commonAPI";
import { useTranslation } from "react-i18next";
import { AddLangToPath } from "../../helper/AddLanguagePath";
import { getShoppingCartLoginStatus } from "../../api/ShoppingCartLoginStatus";
import i18n from "i18next";
import CurrencyConverter from "../../helper/CurrencyConverter";

library.add(faAngleDown, faCaretDown, faBars, faXmark);


const AngleDownIcon = () => (
  <svg
    className="svg-inline--fa fa-angle-down down-arrow"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="angle-down"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <desc>Down arrow</desc>
    <path
      fill="currentColor"
      d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
    ></path>
  </svg>
);

const languages = [
  { id: "en", name: "English" },
  { id: "de", name: "Deutsch" },
  { id: "fr", name: "Francaise" },
  { id: "es", name: "Spanish" },
  { id: "pt", name: "Portugués" },
  { id: "it", name: "Italiano" },
];

const Header = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openProduct, setOpenProduct] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || "en"
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [productData, setProductData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dropdownRef = useRef(null);
  const { isuser_loggedin, is_user } = getShoppingCartLoginStatus();

  //calling currency converter function to set it in local storage on first load
  CurrencyConverter();

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (
        (windowWidth < 1030 && currentWidth >= 1030) ||
        (windowWidth >= 1030 && currentWidth < 1030)
      ) {
        setWindowWidth(currentWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      IPDetails().then((data) => {
        const ipLang = data.country_code.toLowerCase();
        const langExists = languages.some((lang) => lang.id === ipLang);
        if (langExists) {
          setSelectedLanguage(ipLang);
          localStorage.setItem("lang", ipLang);
          i18n.changeLanguage(ipLang);
          if (ipLang !== "en") {
            window.location.pathname = `/${ipLang}`;
          }
        } else {
          setSelectedLanguage("en");
          localStorage.setItem("lang", "en");
          i18n.changeLanguage("en");
        }
      });
    } else {
      const userLang = window.location.pathname.split("/")[1];
      const langExists = languages.some((lang) => lang.id === userLang);
      if (langExists) {
        setSelectedLanguage(userLang);
        localStorage.setItem("lang", userLang);
        i18n.changeLanguage(userLang);
      } else {
        setSelectedLanguage("en");
        localStorage.setItem("lang", "en");
        i18n.changeLanguage("en");
      }
    }
    commonAPI("product-header").then((result) => setProductData(result));
  }, [selectedLanguage]);

  const handleLanguage = (lang) => {
    if (!lang) return;
    const newLang = lang;
    const langExists = languages.some((lang) => lang.id === newLang);
    setDropdownVisible(false);
    if (langExists) {
      setSelectedLanguage(newLang);
      localStorage.setItem("lang", newLang);
      let mypath = window.location.pathname.split("/");
      if (languages.some((lang) => lang.id === mypath[1])) {
        mypath = mypath.slice(2);
      }
      mypath = mypath.filter((path) => path !== "").join("/");
      if (newLang === "en") {
        window.location.pathname = `/${mypath}`;
      } else {
        window.location.pathname = `/${newLang}/${mypath}`;
      }
    } else {
      setSelectedLanguage("en");
      localStorage.setItem("lang", "en");
    }
  };

  const toggleDropdown = () => {
    setIsClicked(!isClicked);
    setDropdownVisible((prevState) => !prevState);
  };

  const openMenuBar = useCallback(() => {
    setOpenMenu((prevOpenMenu) => {
      if (!prevOpenMenu) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style = null;
      }
      return !prevOpenMenu;
    });
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        openMenuBar();
      }
    },
    [openMenuBar]
  );

  const openProductBar = useCallback(() => {
    setOpenProduct((prevOpenProduct) => !prevOpenProduct);
    setTimeout(() => setOpenProduct(true), 100);
  }, []);

  return (
    <>
      <Helmet>
        <html lang={selectedLanguage}></html>
        <meta
          name="description"
          content="Deskshare offers innovative software for screen recording, video surveillance and FTP transfer for Windows users."
        />
        <meta name="keywords" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div
        className="navigation"
        style={
          windowWidth < 1030 && openMenu
            ? { position: "fixed", width: "100%", zIndex: "100" }
            : { position: "relative", width: "100%" }
        }
      >
        <div className="navbar">
          {windowWidth < 1030 ? (
            <button
              className="menu-bar"
              onClick={openMenuBar}
              onKeyDown={handleKeyDown}
            >
              {!openMenu ? (
                <FontAwesomeIcon icon={faBars} className="menu-button" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="menu-button"
                  width="10"
                  height="17"
                  viewBox="0 0 10 17"
                  fill="none"
                >
                  <path
                    d="M9 1L2 8.24138L9 16"
                    stroke="white"
                    strokeWidth="1.75"
                  />
                </svg>
              )}
            </button>
          ) : (
            ""
          )}
          <div className="content-left">
            <a href={AddLangToPath("/")} className="deskshare-logo">
              <LazyLoadImage
                className="logo"
                alt="deskshare logo"
                to="/"
                src="/images/deskshare_logo.svg"
              />
            </a>
          </div>
          <div
            role="button"
            className={`${
              !openMenu || windowWidth > 1030 ? "" : "blur-background"
            }`}
            onClick={() => {
              windowWidth < 1030 ? openMenuBar() : openProductBar();
            }}
          ></div>
          <div
            className={
              !openMenu || windowWidth > 1030 ? "content" : "other-view-content"
            }
          >
            <div className="content-center">
              <div className="items product-container">
                <span>{t("header.product")}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ? (
                  <div className="allproducts">
                    <hr
                      className={`${
                        selectedLanguage === "de" ||
                        selectedLanguage === "fr" ||
                        selectedLanguage === "pt" ||
                        selectedLanguage === "es" ||
                        selectedLanguage === "it"
                          ? "product-catagories-other"
                          : "product-catagories"
                      }`}
                    />
                    <div
                      className="product-dropdown"
                      style={
                        selectedLanguage === "de" ||
                        selectedLanguage === "fr" ||
                        selectedLanguage === "pt" ||
                        selectedLanguage === "es" ||
                        selectedLanguage === "it"
                          ? { width: "125%", left: "-15%" }
                          : null
                      }
                    >
                      {productData
                        ? Object.keys(productData)?.map((category) => {
                            return (
                              <div className="product-block" key={category.id}>
                                <div className="product-content">
                                  <p className="product-type">{category}</p>
                                  <div className="product-list">
                                    {productData[category].map((productId) => {
                                      return (
                                        <>
                                          <Link
                                            className="product-detail"
                                            key={productId.id}
                                            style={
                                              selectedLanguage === "de" ||
                                              selectedLanguage === "fr" ||
                                              selectedLanguage === "pt" ||
                                              selectedLanguage === "es" ||
                                              selectedLanguage === "it"
                                                ? { width: "89%" }
                                                : null
                                            }
                                            onClick={() => {
                                              window.innerWidth > 1030
                                                ? openProductBar()
                                                : openMenuBar();
                                            }}
                                            to={AddLangToPath(
                                              `/${productId.product_slug}`
                                            )}
                                          >
                                            <h5 className="product-name">
                                              {productId.product_name}
                                            </h5>
                                            <p className="product-description">
                                              {productId.product_title}
                                            </p>
                                          </Link>
                                          <hr />
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <Link
                to={AddLangToPath("/download")}
                className="items"
                id="download"
              >
                <span>{t("header.download")}</span>
              </Link>
              <div className="items product-container header-item">
                <span>{t("header.support.title")}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ? (
                  <div className="items-dropdown">
                    <div className="support-list">
                      {/* <a className='product-detail' href={`${process.env.REACT_APP_SUPPORT_FORUM_URL}/forum`}>
                        <h5 className='product-name'>{t('header.support.forum.title')}</h5>
                        <p className='product-description'>{t('header.support.forum.description')}</p>
                      </a>
                      <hr /> */}
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/product-support/")}
                      >
                        <h5 className="product-name">
                          {t("header.support.technical_support.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.support.technical_support.description")}
                        </p>
                      </Link>
                      <hr />
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/lost-license-key/")}
                      >
                        <h5 className="product-name">
                          {t("header.support.lost_license.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.support.lost_license.description")}
                        </p>
                      </Link>
                      <hr />
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/priority-support/")}
                      >
                        <h5 className="product-name">
                          {t("header.support.purchase_support.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.support.purchase_support.description")}
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="items product-container header-item">
                <span>{t("header.resources.title")}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ? (
                  <div className="items-dropdown">
                    <div className="support-list">
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/articles")}
                      >
                        <h5 className="product-name">
                          {t("header.resources.articles.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.resources.articles.description")}
                        </p>
                      </Link>
                      <hr />
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/tutorials-manuals/")}
                      >
                        <h5 className="product-name">
                          {t("header.resources.video_tutorials.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.resources.video_tutorials.description")}
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="items product-container header-item">
                <span>{t("header.company.title")}</span>
                <AngleDownIcon className="down-arrow" />
                {openProduct ? (
                  <div className="items-dropdown">
                    <div className="support-list">
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/about-deskshare/")}
                      >
                        <h5 className="product-name">
                          {t("header.company.about.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.company.about.description")}
                        </p>
                      </Link>
                      <hr />
                      <Link
                        className="product-detail"
                        onClick={() => {
                          windowWidth < 1030 ? openMenuBar() : openProductBar();
                        }}
                        to={AddLangToPath("/contact-us/")}
                      >
                        <h5 className="product-name">
                          {t("header.company.contact.title")}
                        </h5>
                        <p className="product-description">
                          {t("header.company.contact.description")}
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="auth">
              <Link className="signin" to={"https://test.shop.deskshare.com/login/"}>
                {isuser_loggedin
                  ? t("header.my_account")
                  : is_user
                  ? t("header.sign_in")
                  : t("header.sign_up")}
              </Link>
            </div>
          </div>
          <div className="select-language" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className={`${
                dropdownVisible === true ? "lang-box-clicked" : "lang-box"
              }`}
            >
              <MdLanguage className="language-icon" />
              <p>
                {languages.find((lang) => lang.id === selectedLanguage)?.name ||
                  "Select Language"}
              </p>
              <FontAwesomeIcon
                icon={faCaretDown}
                className="language-dropdown"
                style={
                  dropdownVisible ? { rotate: "180deg" } : { rotate: "unset" }
                }
              />
            </button>
            {dropdownVisible && (
              <div className="dropdown">
                {languages.map((lang) => (
                  <button
                    style={
                      selectedLanguage === lang.id
                        ? { backgroundColor: "#ebebeb" }
                        : { backgroundColor: "white" }
                    }
                    key={lang.id}
                    onClick={() => handleLanguage(lang.id)}
                  >
                    {lang.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
