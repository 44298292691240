import React from 'react';
import PropTypes from 'prop-types';
import QuickTips from './QuickTips';
// import NewsLetter from './NewsLetter';

const QuickLetter = ({ quickLinks }) => {
  return (
    <div className='quick-letter'>
      {
        !quickLinks.length > 0 ? "" : <QuickTips quickLink={quickLinks} />
      }
      {/* Hiding for next update */}
      {/* <NewsLetter /> */}
    </div>
  );
};

QuickLetter.propTypes = {
  quickLinks: PropTypes.array
};

export default QuickLetter;