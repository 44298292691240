import React from 'react'
import PropTypes from 'prop-types';
import uuid from 'react-uuid'
import { Link } from 'react-router-dom'
import '../../static/css/homePage/softwareUpdates.css'
import { useTranslation } from 'react-i18next';

const SoftwareUpdates = ({ update }) => {
    const { t } = useTranslation();

    function formatDate(updated_date) {
        let date = new Date(updated_date)

        let day = date.getDate();
        let month = date.toLocaleString(`${localStorage.getItem('lang')}-IN`, { month: 'short' });
        let year = date.getFullYear();

        day = day < 10 ? '0' + day : day;
        let result = `${day}-${month}-${year}`;

        return result;
    }

    return (
        <div className='updates'>
            <div className='updates-name'>
                <span className='update-title'>{t('homepage.software_updates.title')}</span>
                <span className='update-description'>{t('homepage.software_updates.info')}</span>
            </div>
            <div className='product-updates' key={uuid()}>
                <table className='updates-list' >
                    <tbody>
                        <tr>
                            <th><p>{t('homepage.software_updates.product_name')}</p></th>
                            <th><p>{t('homepage.software_updates.version')}</p></th>
                            <th><p>{t('homepage.software_updates.date')}</p></th>
                        </tr>
                        {
                            update.map((update) => {
                                return (
                                    <tr key={uuid()}>
                                        <td className='updated-product-name'>
                                            <div className="product-bullet-name">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="318" height="557" viewBox="0 0 318 557" fill="none">
                                                    <path d="M318 278.279L0 557L76.4214 278.279L0 0L318 278.279Z" fill="black" />
                                                </svg>
                                                <Link to={update.product_slug}><span>{update.product_name}</span></Link>
                                            </div>
                                        </td>
                                        <td className='product-version'>
                                            <Link to={`${update.product_slug}/whatsnew/${update.whats_new_slug}`}>{update.current_version}</Link>
                                        </td>
                                        <td className='update-date'>
                                            <p>{formatDate(update.updated_date)}</p>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div >
    )
}

SoftwareUpdates.propTypes = {
    update: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    }))
}

export default SoftwareUpdates