import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { MdOutlineFileDownload } from "react-icons/md";
import { commonAPI } from '../../api/commonAPI';
import { useTranslation } from 'react-i18next';

const DownloadTable = () => {
    const { t } = useTranslation();
    const [downloadData, setDownloadData] = useState(null);
    const [osVersion, setOsVersion] = useState("Unknown OS");
    useEffect(() => {
        window.scrollTo(0, 0);
        commonAPI('download').then((result) => setDownloadData(result));
    }, []); 

    useEffect(() => {
        if (navigator.userAgentData) {
            navigator.userAgentData.getHighEntropyValues(["platformVersion"])
                .then(ua => {
                    let version = parseFloat(ua.platformVersion);
                    if (version === 0) {
                        setOsVersion("8");
                    } else if (version >= 1 && version <= 10) {
                        setOsVersion("10");
                    } else if (version >= 13) {
                        setOsVersion("11");
                    } else {
                        setOsVersion("Unknown OS");
                    }
                })
                .catch(error => {
                    console.error(error);
                    setOsVersion("Unknown OS");
                });
        } else {
            setOsVersion("Unknown OS");
        }
    }, []);

    if (downloadData) {
        return (
            <div className="download-content">
                {
                    Object.keys(downloadData).map((type) => {
                        return (
                            <div key={uuid()} className="download-type">
                                <p className='type-name'>{type}</p>
                                <table className='download-product'>
                                    <thead className='download-product-heading'>
                                        <tr>
                                            <th>{t('download_page.table.product_name')}</th>
                                            <th>{t('download_page.table.description')}</th>
                                            <th>{t('download_page.table.version')}</th>
                                            <th>{t('download_page.table.platform')}</th>
                                            <th>{t('download_page.table.download')}</th>
                                        </tr>
                                    </thead>
                                    <tbody className='download-product-list'>
                                        {
                                            downloadData[type].map((product) => {
                                                return (
                                                    <tr key={uuid()}>
                                                        <td>
                                                            <Link to={`/${product.product_slug}`}>
                                                                <div className="download-product-name">
                                                                    <img className='product-download-image' src={`${process.env.REACT_APP_BACKEND_URL}/media/${product.product_icon}`} alt="" />
                                                                    <span>{product.product_name}</span>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/${product.product_slug}`}>
                                                                {product.product_title}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/${product.product_slug}`}>
                                                                {product.current_version}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link to={`/${product.product_slug}`}>
                                                                <div className="download-version">
                                                                    <span>
                                                                        {product.supporting_os?.split(' ')?.map((word) =>
                                                                        (word === osVersion
                                                                            ? <strong key={uuid()}>{` ${word}`}</strong>
                                                                            : <span key={uuid()}>{` ${word}`}</span>)
                                                                        )}
                                                                    </span>
                                                                    <span>{product.product_server}</span>

                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className='product-download-button'
                                                                onClick={() => { window.open(product.download_link, '_blank') }}>
                                                                <MdOutlineFileDownload className='downloadpage-icon' />
                                                                {t('download_page.table.download')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default DownloadTable
