import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/comments.css';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const Comments = ({ review }) => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if ((windowWidth < 1031 && currentWidth >= 1031) || (windowWidth >= 1031 && currentWidth < 1031)) {
                setWindowWidth(currentWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    let { productSlug } = useParams();

    return (
        <div className="customer-comments">
            <div className="comments-heading">
                <p>{t('product_page.comments.title')}</p>
            </div>
            <div className="comment-card-container">
                <div className="comment-card">
                    {review[0].review_title ? <h4><q>{review[0]?.review_title}</q></h4> : ""}
                    <blockquote className='quote'>
                        <p>{review[0]?.review}</p>
                        <p>-{" "}{review[0]?.reviewer_name}</p>
                    </blockquote>
                </div>
                <div className="rating">
                    <p>Average User Rating:</p>
                    {(windowWidth >= 1031) ?
                        <>
                            <p> <span className="rating-star">{"★".repeat(5)}</span> - <span>Excellent</span> </p>
                            <Link to={AddLangToPath(`/${productSlug}/reviews/`)} className='more-reviews'>{t('product_page.comments.more')}{" "}{">>"}</Link>
                        </> :
                        <div className='rating-block'>
                            <span> <span className="rating-star">{"★".repeat(5)}</span> - <span>Excellent</span> </span>
                            <Link to={AddLangToPath(`/${productSlug}/reviews/`)} className='more-reviews'>{t('product_page.comments.more')}{" "}{">>"}</Link>
                        </div>}
                </div>
            </div>
        </div >
    );
};

Comments.propTypes = {
    review: PropTypes.array
};

export default memo(Comments);