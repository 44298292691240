import React, { useEffect, useState } from 'react'
import '../static/css/help.css';
import { Link, useParams } from 'react-router-dom';
import { fetchData } from '../api/productPageAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../helper/AddLanguagePath';

const Help = () => {
    const { t } = useTranslation();
    const { productSlug } = useParams();
    const [version, setVersion] = useState(null);
    if (version) {
        document.title = `${version[0].product_name}: ${t('whats_new.title')}?`;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData(`${productSlug}/version`).then((result) => setVersion(result));
    }, [productSlug]);

    const changeImagePath = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;

        const anchorTags = tempDiv.getElementsByTagName('a');
        for (let i = 0; i < anchorTags.length; i++) {
            const anchor = anchorTags[i];
            const href = anchor.getAttribute('href');
            anchor.setAttribute('href', AddLangToPath(`${href}`));
        }

        return tempDiv.innerHTML;
    }

    if (version) {
        return (
            <div className="help-page">
                <div className="help-header">
                    <div className="product-name-title">
                        <Link to={AddLangToPath(`/${productSlug}`)}><h1>{version[0].product_name}</h1></Link>
                    </div>
                    <div className="help-heading">
                        <h2>{t('whats_new.title')} {version[0].product_name}</h2>
                    </div>
                </div>
                {
                    version.sort((a, b) => b.version_number - a.version_number).map((version) => {
                        return (
                            <div className='help-article'>
                                <div key={version.id} className="version-number">
                                    <h3>{t('whats_new.version')} {version.version_number}</h3>
                                </div>
                                <div className='help-content' dangerouslySetInnerHTML={{ __html: changeImagePath(version.update_details) }} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default Help