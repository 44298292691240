import React from 'react';
import PropTypes from 'prop-types';
import '../../static/css/productPage/comparison.css';
import '../../static/css/productPage/characteristics.css';

const Comparison = ({ comparisonTable }) => {
    if (comparisonTable.length > 0 && Object.values(comparisonTable[0])[1] === true) {
        return (
            <div className='comparison' dangerouslySetInnerHTML={{ __html: Object.values(comparisonTable[0])[0] }}></div>
        );
    }
};

Comparison.propTypes = {
    comparisonTable: PropTypes.array
};

export default Comparison;