export const mailProductLink = async (data) => {

    const apiData = {
        email: data.email,
        product_link: data.downloadProduct,
    };

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send_product_link/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
    })
        .then(response => {
            return response.json().then(data => {
                if (!response.ok) {
                    let err = new Error(data.message);
                    err.status = response.status;
                    throw err;
                }
                return {
                    message: data.message,
                    status: response.status
                };
            });
        })
        .catch((error) => ({
            message: error.message,
            status: error.status
        }));
};