import React, { useEffect, useState } from 'react'
import ImageSlider from '../components/homePage/ImageSlider'
import '.././static/css/homePage/homePage.css'
import SoftwareUpdates from '../components/homePage/SoftwareUpdates'
import Categories from '../components/homePage/Categories'
import DisplayProducts from '../components/homePage/DisplayProducts'
import homeData from '.././data/homePage.json'
// import FreeTrials from '../components/homePage/FreeTrials'
// import ActiveDiscussions from '../components/homePage/ActiveDiscussions'
import HomeFooter from '../components/homePage/HomeFooter'
import { commonAPI } from '../api/commonAPI'
import { useTranslation } from 'react-i18next'

let cache = {};

export const HomePage = () => {
  const { t } = useTranslation()
  document.title = `Deskshare - ${t('title')}`

  const [HomeData, setHomeData] = useState(null);

  useEffect(() => {
    if (cache['homepage']) {
      setHomeData(cache['homepage']);
    } else {
      commonAPI('homepage').then((result) => {
        cache['homepage'] = result;
        setHomeData(result);
      });
    }
  }, []);

  if (HomeData) {
    return (
      <>
        <ImageSlider images={HomeData.slider_images} />
        <Categories productId={HomeData.product_list} />
        <div className='home-page'>
          <div className="home-page-block">
            <DisplayProducts product={HomeData.product_list} />
          </div>
          <SoftwareUpdates update={HomeData.product_updates} />
          {/* <FreeTrials /> */}
           {/* Hiding for next update */}
          {/* <ActiveDiscussions discussion={homeData.discussion_list} /> */}
        </div>
        <HomeFooter footer={homeData.footer} />
      </>
    )
  }
}
